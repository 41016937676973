.backgroung-image-contact{
    background-image: url(../assets/images/contact-banner.jpg);
    background-size: 100% 100%;
    width: 100%;
    background-repeat: no-repeat;
}

.header-Careers-contents h1{
    color: #005e7c !important;
    font-weight: 600;
    margin: 0;
    letter-spacing: 1px;
    margin-left: 32px;
}
.email-address {
    padding-top: 20px;
    justify-content: center;
    align-items: center;
}
.email-address .email a   {
    color: #000;
    font-size: 27px;
    padding-left: 10px !important;
    text-decoration: none;
    
}
.email-address .email {
    padding-left: 10px !important;
    text-decoration: none;
}
.email {
    padding-left: 13px;
}


.Location-details-page .location-contact{display:inline-block;width:49%;margin:35px 0;vertical-align:top;   }
.Location-details-page .location-contact:hover img{
 -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: bounce;
  animation-name: bounce;
  -webkit-transform-origin: center bottom;
  transform-origin: center bottom;
  
  }
.Location-details-page .location-contact.mr-btm{margin-bottom:0;}
.Location-details-page .location-contact p{margin:15px 0 0 0;color:#333f4f;font-weight:700;font-size: 18px; line-height: 1.5;}



.contactForms{  background: url(../assets/images/contact-form-bg.jpg) no-repeat;    color: #fff;}
 
.contactForms input{    padding:5px 0; 
background:none;
font-size:16px;
    margin: 0;
    height: auto;
    border: none;
    box-shadow: none;
    border-bottom: solid 2px #ccc;
	color:#005e7c;
    border-radius: 0;}
	.contactForms select{width:100%;  padding: 11px 0;  border: none;  box-shadow: none; border-bottom: solid 2px #ccc; background:none; color:#fff;font-size:16px;}
		.contactForms  label{ display:block;   }
		.contactForms .submit{background:none; color:#000; font-weight:600; border:solid 2px #005e7d;  border-radius: 18px; font-size:13px; padding:8px 22px; margin-top:10px;}
		.contactForms .submit:hover{background:#005e7d; color:#fff;}
		.contactForms .col-md-6.cfleft{padding: 0 0px 0 0;}
		.contactForms .col-md-6.cfright{padding: 0 0 0 0px;}
		.contactForms .col-md-6.cfright .captchatext{padding: 0 20px 0 0;}
		.contactForms .col-md-6.cfright #imgdiv{margin-right: 10px;}
		.contactForms .form-groups{margin:0 0 40px;}
		.contactForms input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #000;
    opacity: 1; /* Firefox */
}

.contactForms input:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #000;
}

.contactForms input::-ms-input-placeholder { /* Microsoft Edge */
    color: #000;
}
.contactForms textarea::-webkit-input-placeholder {
color: #000;
}

.contactForms textarea:-moz-placeholder { /* Firefox 18- */
color: #000;  
}

.contactForms textarea::-moz-placeholder {  /* Firefox 19+ */
color: #000;  
}

.contactForms textarea:-ms-input-placeholder {
color: #000;  
}
	.contactForms textarea{width:100%;height:80px; background:none; margin:0;border: none;box-shadow: none;border-bottom: solid 2px #ccc; color:#005e7c;}
	.contactForms p
    {font-size: 20px;
        color: black;
        float: left;}
	.contactForms h2{color:#010101; position:relative; padding-bottom:12px;}
	.contactForms h2:before{content:''; position:absolute; background:#005e7d; width:5%; left:0; bottom:0; height:4px;}
	.contactForms .col-md-12{padding:0 ;}
	
.careerFormIns button {
    float: left;
}
.contactForms-heading {
   padding-bottom: 30px;
   font-size: 30px;
}
.map-location{
   width: 100%;
}