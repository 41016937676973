.backgroung-image-noeticScience {
    background-image: url(../assets/images/noetic_science.png);
    background-size: 100% 100%;
    width: 100%;
    background-repeat: no-repeat;
    height: 800px;
}

.navbar-collapse.collapse
 {
	 background:#005f7a;
	 border:none;
	 border-radius:0;
 }
 .navbar-default .navbar-collapse, .navbar-default .navbar-form
 {
      background: #005f7a !important;
      padding:0;
      margin:0;
          }
          .navbar-collapse.collapse
          {
              background: #005f7a !important;
         padding: 0;
         border: none;
         border-radius: 0;
          }
          .noc-img  img{
            width: 600px;
          }
          .thisPractices h4 {
            font-weight: 600;
line-height: 34px;
font-size: 24px;
padding-bottom: 20px;
          }
          .testimonials-bg .para-highlight {
            padding-top: -20px;
            color: #005e7d;
            font-weight: 500;
          }
          .img-width img{
            width: 100%;
          }

          

