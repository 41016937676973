.backgroung-image-careers{
    background-image: url(../assets/images/career-banner.jpg);
    background-size: 100% 100%;
    width: 100%;
    background-repeat: no-repeat;
}
.header-Careers-contents h1{
    padding-top: 230px;
    text-align: center;
    color: #005e7c !important;
    font-weight: 400;
    margin: 0;
    letter-spacing: 1px;
    margin-left: 32px;
    justify-content: center;
    align-items: center;
    padding-bottom: 20px;
}
.header-Careers-contents h2{
    text-align: center;
    padding-bottom: 230px;
    color: #005e7c !important;
    font-weight: 600;
    margin: 0;
    letter-spacing: 1px;
    margin-left: 32px;
    justify-content: center;
    align-items: center;
}

.careerOut{padding:80px 0; margin:0; text-align:left; background:#f6f6f6;}
	.careerOut .col-md-6 h2{padding:30px 0 0 30px; margin:0 0 40px; font-size:24px; text-transform:uppercase;font-weight: 700;}
	.careerOut .col-md-6 h2:before{content:''; position:absolute; width:10%; height:3px; top:55px;} 
    .careerOut .form-control{border-radius: 0; margin:0;padding:10px 12px; height:auto;}
	.careerOut .form-group label input{margin: 0;background: #fff;width: 100%;padding: 4px;border: solid 1px #ccc;font-size: 14px;color: #9e9a9a;  font-weight: 500;}
	.careerOut .form-group label{display:block;}
	.careerOut .form-group label span{font-size:12px; text-align:left; margin:0 0 10px; padding:0; float:left;  color: #ab9d9e; font-weight:500;}
	.careerOut select{    width: 100%;    margin: 0;    font-size: 14px;    color: #ab9d9e;    padding:8px 12px;}
	.careerOut .form-group { margin-bottom: 25px;}
	.careerOut .form-group.last { margin-bottom: 0px;}
	.careerOut .form-group.last textarea { margin-bottom: 20px;}
	.careerOut textarea{width:100%; height:80px;}
	.careerOut .col-md-12{padding:0;}
	.careerOut .submit{border: solid 2px #005e7d;    border-radius: 20px;    padding: 8px 20px;    font-size: 14px;    color: #686868;background: #fff;}
	.careerOut .submit:hover{background:#005e7d; color:#fff;}


      ul, li { list-style: none; }
  
      h1 {
        text-align: center;
        margin-bottom: 20px;
      }
  
  
  
      .faq li { padding: 20px; }
  
      .faq li.q {
        border: 2px #005e7c solid;
        cursor: pointer;
        margin-bottom: 3px;
        /* padding: 13px 13px 13px 65px; */
        text-decoration: none !important;
        position: relative;
        background: #fff;
        color: #222;
        font-weight: 600;
        border-radius: 5px;
      }
      .innerContent .containe ul {
        padding-left: 0px !important;
      }
  
      .faq li.a {
        background: #005e7d;
        display: none;
        color:#fff;
      }
  
      .faq li.q i {
        float: right;
        transition: transform 0.3s ease-in-out;
      }
  
      .faq li.q.rotate i::before {
        content: "\f068"; /* Cross icon */
      }
  
      .faq li.q i::before {
        content: "\f067"; /* Plus icon */
      }
  
      @media (max-width:800px) {
        #container { width: 90%; }
      }
      .accordion-button {
        margin-bottom: 3px;
/* padding: 13px 0 13px 65px; */
text-decoration: none !important;
position: relative;
background: #fff;
color: #222;
font-size: 18px;
font-weight: 600;
      }

      .accordion-button:not(.collapsed) {
        color: white;
        background-color: #005e7c;
        box-shadow: inset 0 -1px 0 rgba(0,0,0,.125);
      }