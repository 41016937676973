.custom-dropdown-menu {
    background-color: #07435a;
    width: 100%;
  }

  .paddind-font {
    font-size: 14px;
    padding-left: 20px;
  }
  .paddind-fonts {
    padding-left: 17px;
  }

  .submit {
    float: left;
  }

  