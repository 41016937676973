
*,
*::after,
*::before {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.backgroung-image-alliances {
    background-image: url(../assets/images/OUR_ALLIANCES.jpg);
    background-size: 100% 100%;
    width: 100%;
    background-repeat: no-repeat;
    height: 700px;
}
.ourAllienceOutses
{background:#fff url(../assets/images/our-alliances-content-bg.jpg) repeat-x 0 bottom; 
    padding:0px 0  160px 0; 
    text-align:center;
 }
 .ourAllienceContents{
    background:#fff;
    box-shadow:-1px -1px 12px #d6d6d6;
     padding:0px 0px 160px 0px;    
     margin-top: 70px; }
.hear-underline {
    width: 100px;
    align-items: center;
    justify-content: center;
    text-align: center;
}
.what-we-dosssss {
	font-size: 20px !important;
    font-weight: 700;
    color: #005e7d;
    line-height: 30px;
    letter-spacing: 1px;
    font-size: 45px;
	text-align: left !important;
}