.backgroung-image-behaviral{
    background-image: url(../assets/images/behavioural_skill.jpg);
    background-size: 100% 100%;
    width: 100%;
    background-repeat: no-repeat;
    height: 700px;
}
.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background-color: #005e7d;
    color: #60b5cd;
    border-radius: 0px;
  }
  
  .nav-toggle {
    display: none;
    cursor: pointer;
  }
  
  .bar {
    display: block;
    width: 25px;
    height: 3px;
    background-color: #60b5cd;
    margin: 5px auto;
    transition: background-color 0.3s;
  }
  
  .nav-links {
    display: flex;
    list-style: none;
  }
  
  .nav-links li {
    margin-right: 20px;
    padding-top: 20px;
  }
  
  .nav-links a {
    color: #60b5cd;
    text-decoration: none;
    border-right: 1px solid #60b5cd;
    padding-right: 20px;
  }
  
  @media (max-width: 768px) {
    .nav-toggle {
      display: block;
    }
  
    .nav-links {
      flex-direction: column;
      position: absolute;
      top: 70px;
      left: 0;
      background-color: #005e7d;
      width: 100%;
      transform: translateX(-100%);
      transition: transform 0.3s;
      z-index: 1;
    }
  
    .nav-links.open {
      transform: translateX(0);
    }
  }
  