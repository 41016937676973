.backgroung-image-testimonails {
    background-image: url(../assets/images/TESTIMONIAL.jpg);
    background-size: 100% 100%;
    width: 100%;
    background-repeat: no-repeat;
    height:800px ;
}
.testimonials-padding {
    padding-bottom:600px;
}

.Testimonials-success-work >h3 {
    letter-spacing: 9px;
    display: block;
    color: #adadad;
    font-weight: 500;
    text-transform: uppercase;
    font-size: 16px;
    padding-top: 60px;
  }

  
.Testimonials-message-one p::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 2em;
    height: 0;
    width: 0;
    border: 8px solid transparent;
    border-top-color: transparent;
    border-top-color: #115e7c;

}
.testimonials-title-bg{
	background: url(../assets/images/our-alliances-content-bg.jpg);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: inherit;
	margin: 0px 0px 0px 0px;
	padding: 150px 0px 150px 0px;
}
.testimonials-bg{
	margin:0px 0px 0px 0px;
	padding: 40px 0px 40px 0px;
}
.testimonials-bg h5{
	letter-spacing: 9px;
    display: block;
    color: #adadad;
    font-weight: 500;
    padding: 30px 20px;
    text-transform: uppercase;
    font-size: 16px;
	font-family: 'Open Sans', sans-serif;
	text-align: center;
}
.testimonials-bg h2{
	margin: 0px 0px 25px 0px;
    padding: 0px 0px 0px 0px;
    font-family: 'Open Sans', sans-serif;
    font-size: 46px;
    font-weight: 500;
    color: #000000;
	text-align: center;
	position: relative;
}
.testimonials-bg h2:before{
	content: '';
    position: absolute;
    width: 60px;
    height: 6px;
    bottom: 0;
    left: 50%;
    margin-left: -30px;
	top:65px;
}
.testimonials-bg h2 span{
	color: #005e7d;
	font-weight: 700;
} 
.testimonials-bg ul{
	margin: 40px 0px 0px 0px;
	padding: 0px 0px 0px 0px;
	display: inherit;
}
.testimonials-bg ul li{
	margin: 0px 10px 0px 10px;
	padding: 0px 0px 0px 0px;
	display: inline-block;
	width: 33%;
}
.testimonials-bg ul li .box{
	margin: 0px 0px 0px 0px;
	padding: 0px 0px 0px 0px;
}
.testimonials-bg ul li .box p{
	position: relative;
    background: #005e7d;
    color: #ffffff;
    padding: 2em;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    line-height: 23px;
    border-radius: 0.25em;
    margin-bottom: 1em;
    font-size: 14px;
    font-weight: 300;
    text-align: justify;
}
.testimonials-bg ul li .box p::after{
	content: '';
    position: absolute;
    top: 100%;
    left: 2em;
    height: 0;
    width: 0;
    border: 8px solid transparent;
    border-top-color: #115e7c;
}
.testimonials-bg ul li .box .in-box{
	margin: 5px 0px 0px 0px;
	padding: 0px 0px 0px 0px;
}
.testimonials-bg ul li .box .in-box img{
	margin: 0px 5px 0px 0px;
	padding: 0px 0px 0px 0px;
	width: 50px;
	float:left;
}
.testimonials-bg ul li .box .in-box h4{
	margin: 0px 0px 0px 0px;
	padding: 0px 0px 0px 0px;
	font-family: 'Open Sans', sans-serif;
	font-size: 16px;
	font-weight: 500;
	color: #7c7c81;
}
.testimonials-bg ul li .box .in-box h6{
	margin: 5px 0px 0px 0px;
	padding: 0px 0px 0px 0px;
	font-family: 'Open Sans', sans-serif;
	font-size: 15px;
	font-weight: 400;
	color: #7c7c81;
}
.footer-bg{
	background: url(../assets/images/footer-bg.jpg) repeat;
	padding: 30px 0px 30px 0px;
}
.footer-bg .first{
	margin:0px 0px 0px 0px;
	padding:0px 0px 0px 0px;
}
.footer-bg .first h4{
	margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
    font-family: 'Roboto', sans-serif;
    font-size: 20px;
    font-weight: 400;
    color: #ffffff;
}
.footer-bg .first p{
	margin: 10px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
}
.footer-bg .first p a{
	margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: #ffffff;
	text-decoration: none;
}
.footer-bg .first p a:hover{
	border-bottom: 2px solid #ffffff;
}
.footer-bg .second{
	margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
    text-align: left;
}
.footer-bg .second h4{
	margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
    font-family: 'Roboto', sans-serif;
    font-size: 20px;
    font-weight: 400;
    color: #ffffff;
}
.footer-bg .second ul{
	margin: 10px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
}
.footer-bg .second ul li{
	margin: 0px 0px 5px 0px;
    padding: 0px 0px 0px 0px;
	display: block;
}
.footer-bg .second ul li a{
	margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: #ffffff;
	text-decoration: none;
}
.footer-bg .second ul li a:hover{
	border-bottom: 2px solid #ffffff;
}
.footer-bg .second p{
	margin: 10px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: #ffffff;
}
.footer-bg .second ul{
	margin: 10px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
}
.footer-bg .second ul li{
	margin: 0px 10px 0px 0px;
    padding: 0px 0px 0px 0px;
	display: block;
}
.footer-bg .second ul li i{
	color: #a2a2a2;
	font-size: 24px;
	margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
}
.footer-bg .second ul li i:hover{
	color:#ffffff;
}
.footer-bg .second ul li a:hover{
	border-bottom: none !important;
}
.footer-bg .footer-box{
	border-bottom: dashed 1.5px #686868;
	padding:0px 0px 30px 0px;
}
.footer-bg h6{
	margin: 0px 0px 0px 0px;
    padding: 30px 0px 0px 0px;
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    font-weight: 400;
    color: #ffffff;
	text-align: center;
}
.first {
    float: left;
}
.second {
    float: left;
}
.first h4{
    float: left;
    padding-bottom: 20px;
}

.testimonials-bg{
	margin:0px 0px 0px 0px;
	padding: 40px 0px 40px 0px;
}
.testimonials-bg h5{
	letter-spacing: 9px;
    display: block;
    color: #adadad;
    font-weight: 500;
    text-transform: uppercase;
    font-size: 16px;
	font-family: 'Open Sans', sans-serif;
	text-align: center;
}
.testimonials-bg h2{
	margin: 0px 0px 25px 0px;
    padding: 0px 0px 0px 0px;
    font-family: 'Open Sans', sans-serif;
    font-size: 46px;
    font-weight: 500;
    color: #000000;
	text-align: center;
	position: relative;
}
.testimonials-bg h2:before{
	content: '';
    position: absolute;
    width: 60px;
    height: 6px;
    bottom: 0;
    left: 50%;
    margin-left: -30px;
	top:65px;
}
.testimonials-bg h2 span{
	color: #005e7d;
	font-weight: 700;
} 
.testimonials-bg ul{
	margin: 40px 0px 0px 0px;
	padding: 0px 0px 0px 0px;
	display: inherit;
}
.testimonials-bg ul li{
	margin: 0px 10px 0px 10px;
	padding: 0px 0px 0px 0px;
	display: inline-block;
	width: 33%;
}
.testimonials-bg ul li .box{
	margin: 0px 0px 0px 0px;
	padding: 0px 0px 0px 0px;
}
.testimonials-bg ul li .box p{
	position: relative;
    background: #005e7d;
    color: #ffffff;
    padding: 2em;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    line-height: 23px;
    border-radius: 0.25em;
    margin-bottom: 1em;
    font-size: 14px;
    font-weight: 300;
    text-align: justify;
}
.testimonials-bg ul li .box p::after{
	content: '';
    position: absolute;
    top: 100%;
    left: 2em;
    height: 0;
    width: 0;
    border: 8px solid transparent;
    border-top-color: #115e7c;
}
.testimonials-bg ul li .box .in-box{
	margin: 5px 0px 0px 0px;
	padding: 0px 0px 0px 0px;
}
.testimonials-bg ul li .box .in-box img{
	margin: 0px 5px 0px 0px;
	padding: 0px 0px 0px 0px;
	width: 50px;
	float:left;
}
.testimonials-bg ul li .box .in-box h4{
	margin: 0px 0px 0px 0px;
	padding: 0px 0px 0px 0px;
	font-family: 'Open Sans', sans-serif;
	font-size: 16px;
	font-weight: 500;
	color: #7c7c81;
}
.testimonials-bg ul li .box .in-box h6{
	margin: 5px 0px 0px 0px;
	padding: 0px 0px 0px 0px;
	font-family: 'Open Sans', sans-serif;
	font-size: 15px;
	font-weight: 400;
	color: #7c7c81;
}
