
*,
*::after,
*::before {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body{
  padding-right: unset !important;
} 
  .header-content {
	text-align: left;
	font-size: 1em;
	font-weight: 500;
	color: #005e7c;
	/* text-shadow: 2px 2px black; */
  }
  .header-content2 {
	text-align: left;
	font-size: 1em;
	font-weight: 300;
	color: white;
	/* text-shadow: 2px 2px black; */
  }
  .blinking-button {
    display: inline-block;
    padding: 10px 20px;
    font-size: 16px;
    background-color: #3498db;
    color: #fff;
    border: none;
    cursor: pointer;
    animation: blink 1s infinite alternate;
  }

  @keyframes blink {
    to {
      opacity: 0.5;
    }
  }
  .text-reveal {
    overflow: hidden;
    white-space: nowrap;
    position: relative;
    font-size: 24px;
    animation: reveal 1s linear forwards;
  }

  @keyframes reveal {
    0% {
      width: 0;
    }
    100% {
      width: 100%;
    }
  }
  .carousel-caption {
	font-size: 3em;
	font-weight: 300;
	width: 100%;
	position: absolute;
	bottom: 230px;
	left: -10%;
	color: #005e7c;
	text-decoration: none;
	text-shadow: 2px 2px #df433b;
	animation-delay: 1.5s;
	animation-duration: 1.5s;
  }
  .btn-teo-caption {
	width: 155px;
	border-radius: 500px !IMPORTANT;
	border: 3px solid white;
	margin-top: 6px;
	margin-right: 21%;
	background-color: #005e7c;
	font-weight: bold;
	color: white;
  }
  .btn-teo-caption:hover {
	background-color: white;
	  color: #106470;
	border: 3px solid #106470;
	  transition-property: ease 1s;
  }
  #caption1-button {
	font-size: 2em;
	font-weight: 300;
	position: absolute;
	bottom: -10px;
	left: 40%;
	color: black;
	text-decoration: none;
  }

html {
  font-size: 100%;
  scroll-behavior: smooth;
  overflow-x: hidden;
}
/* 
body {
  font-weight: 400;
  color: #9698a6;
  font-size: 0.875rem;
  line-height: 1.5;
  overflow-x: hidden;
} */

@media (min-width: 64em) {
  body {
    font-size: 1.125rem;
  }
}

a {
  text-decoration: none;
}

h1,
h2,
h3 {
  color: #2d314d;
  font-weight: 500;
}

h2 {
  font-size: 1.875rem;
  margin-bottom: 1.875rem;
}

@media (min-width: 64em) {
  h2 {
    font-size: 2.25rem;
    margin-bottom: 2.25rem;
  }
}

.flex {
  display: flex;
}

.flex-jc-sb {
  justify-content: space-between;
}

.flex-jc-c {
  justify-content: center;
}

.flex-ai-c {
  align-items: center;
}
@media (min-width: 64em) {
  .container {
    max-width: 69.375rem;
    margin: 0 auto;
  }
}
@media (max-width: 63.9375em) {
  .hide-for-mobile {
    display: none;
  }
}

@media (min-width: 64em) {
  .hide-for-desktop {
    display: none;
  }
}

.fade-in {
  opacity: 1;
  visibility: visible;
}

.fade-out {
  opacity: 0;
  visibility: hidden;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: #2d314d linear-gradient(to bottom, #2d314d, transparent);
  transition: 0.5s ease-in-out;
}

.success-headings {
	font-size: 75px;
    font-weight: 700;
    color: black;
    line-height: 60px;
    padding: 0 0 40px;
    margin: 0 0 40px;
    position: relative;
    text-transform: uppercase;
    letter-spacing: 1px;
}
.success-heading {
	font-size: 60px;
    font-weight: 700;
    color: #005e7d;
    line-height: 60px;
    padding: 0 0 0px;
    margin: 0 0 0px;
    position: relative;
    text-transform: uppercase;
    letter-spacing: 1px;
	text-align: left;
}
.scroll-card-bg {
  background: #2d2d2d;
}
.success-heading-mini {
  padding: 0;
  margin: 0 0 10px;
  font-weight: 700;
  color: #000;
	  text-align: left;
    color: #000;
    font-size:21px;
}
.what-we-do {
	font-size: 40px;
    font-weight: 400;
    color: black;
    line-height: 60px;
    letter-spacing: 1px;
	text-align: left !important;
}
.offcanvas-title {
  color: #c4ced7;
}
.offcanvas-body {
  padding:0;
  align-items:center;
}
.offcanvas-body a {
  color: #c4ced7;
  text-decoration: none;
}
ul.social-media {
  list-style-type: none;
  padding: 60;
  margin: 0;
  display: flex;
   justify-content: center; /* Center horizontally */
      align-items: center; /* Center vertically */

}

/* Adjust margin-right between icons */
ul.social-media li {
  margin-right: 10px;
  font-size: 20px;
}
.offcanvas-body a:hover {
  color: gray;
}
.what-we-dos {
	font-size: 40px;
    font-weight: 700;
    color: black;
    line-height: 30px;
    text-transform: uppercase;
    letter-spacing: 1px;
	text-align: left !important;
}
.what-we-doss {
  font-size: 75px;
  font-weight: 400;
  color: black;
  line-height: 60px;
  text-transform: uppercase;
  letter-spacing: 1px;
	text-align: left !important;
}
.what-we-dossssss {
  font-size: 75px;
  font-weight: 700;
  color: #005e7d;
  line-height: 60px;
  text-transform: uppercase;
  letter-spacing: 1px;
	text-align: left !important;
}
.success-heading-para {
  padding: 0;
  margin: 0 0 20px;
    margin-bottom: 20px;
  color: #333333;
  text-align: justify;
  font-size: 17px;
}
.hwhatwedo{background:url(../assets/images/h-what-wedo-bg.jpg) repeat; padding:50px 0 0;}
.homeIntro{
    background: #005e7d;
    padding: 25px;
	border-radius: 0 50px 50px 0;
}
/* .nav-underline {
  color:#005e7c;
  background-color: 4px solid white;
} */
.section-padding {
	text-align: center;
  background: #f3f3f3;
}
.card-btn {
	padding: 20px;
	border-radius: 40px;
	border-color: red;
}
.custom-button {
    display: inline-block;
    padding: 2px 15px;
    background-color: white;
    color: #696969;
    border: none;
    text-align: center;
	border:2px solid #005e7c;
    text-decoration: none;
    font-size: 16px;
    cursor: pointer;
	margin-top: 10px;
    border-radius: 40px;
  }

  .custom-button:hover {
    background-color: #005e7c;
	color: white;
  }
@media (min-width: 40em) {
  .section-heading {
    text-align: start;
    margin-bottom: 2.8125rem;
  }
}

@media (min-width: 64em) {
  .section-heading {
    width: 60%;
  }
}
.animated-hr {
    border: none;
    border-top: 7px solid #005e7d;
    width: 30%;
    margin: 20px auto;
    animation: slideIn 3s infinite linear;
  }
  .animated-hrs {
    border: none;
    border-top: 7px solid #005e7d;
    width: 10%;
	margin-left: 60px;
    animation: slideIn 3s infinite linear;
	text-align: left;
  }

  @keyframes slideIn {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(100%);
    }
  }

.features .features-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding-top: 0px;
}

.features .feature {
  text-align: center;
}

@media (min-width: 40em) {
  .features .feature {
    /* text-align: start; */
    flex: 1 0 calc(50% - 30px);
  }
}

@media (min-width: 64em) {
  .features .feature {
    flex: 1;
  }
}

.features .feature-icon {
  margin-bottom: 1.875rem;
}
.off-canvas-container {
  position: fixed;
  top: 0;
  left: -100%; /* Start off-canvas */
  width: 100%;
  height: 100%;
  overflow: hidden;
  transition: left 0.5s ease-in-out; /* Add smooth transition */
}

.features .feature-title h3 {
  font-size: 17px;
  margin-bottom: 1rem;
  color: #000;
  font-weight: 600;
}

@media (min-width: 64em) {
  .features .feature-title h3 {
    font-size: 16px;
  }
}

.features .feature-description p {
  padding: 15px;
  font-size:14px;
  text-align: justify;
  letter-spacing: 0.px;
  color: #333333;
}
.features .feature-description  a{
  text-decoration: none;
}

.articles {
	background:url(../assets/images/happy-clients-bg.jpg) repeat; padding:50px 0 0;
}
.container p a {
	text-decoration: underline;
}
.container p a:hover {
	text-decoration: none;
}

.articles .articles-grid {
  display: grid;
  gap: 1.875rem;
  grid-template-columns: 1fr;
}

@media (min-width: 40em) {
  .articles .articles-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 64em) {
  .articles .articles-grid {
    grid-template-columns: repeat(4, 1fr);
  }
}

.articles .article {
  background: #fff;
  border-radius: 0.3125rem;
  box-shadow: 0 0.1875rem 0.75rem rgba(0, 0, 0, 0.15);
  transition: transform 0.2s ease-in-out;
  overflow: hidden;
}

.articles .article:hover {
  transform: scale(1.05);
  cursor: pointer;
}

.articles .article-image {
  height: 12.5rem;
}

.articles .article-image img {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.articles .article-text {
  padding: 1.875rem 1.5625rem 2.75rem 1.5625rem;
}

@media (min-width: 40em) {
  .articles .article-text {
    padding: 1.875rem 1.5625rem 1.5625rem 1.5625rem;
  }
}

.articles .article-author span {
  display: block;
  font-size: 0.75rem;
  margin-bottom: 0.75rem;
}

.articles .article-title h3 {
  margin-bottom: 0.5rem;
  font-size: 1rem;
  line-height: 1.2;
  transition: color 0.2s ease-in-out;
}

.articles .article-title h3:hover {
  color: #31d35c;
}

.articles .article-description p {
  font-size: 0.8125rem;
  line-height: 1.3;
}





 .carousel-indicators {
	right: 50%;
	top: auto;
	bottom: 0px;
	margin-right: -19px;
	display:none;
}




	 .pics {
		position: relative;
	}
	.carousel-bg1 {
		background-image: url("../assets/images/banner-img1.jpg");
		background-repeat: no-repeat;
		background-size: cover;
		background-position: top center;
		position: relative;
	}
	.carousel-bg1::after {
		content: "";
		position: absolute;
		left: 0;
		top: 0;
		height: 100%;
		width: 100%;
		background-color: black;
		opacity: 0.7;
		z-index: -1;
	}
	.carousel-bg2 {
		background-image: url("../assets/images/banner-img2.jpg");

		background-repeat: no-repeat;
		background-size: cover;
	}
	.carousel-bg3 {
		background-image: url("../assets/images/banner-img4.jpg");

		background-repeat: no-repeat;
		background-size: cover;
	}
  .carousel-bg4 {
		background-image: url("../assets/images/banner-img3.jpg");

		background-repeat: no-repeat;
		background-size: cover;
	}
  .carousel-bg5 {
		background-image: url("../assets/images/banner-img5.jpg");
     background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
	}
  .mute {
    padding: 0px 10px 0px 10px;
    background: #005e7c;
    color: white;
  }
	.slider {
		padding-top:325px;
		padding-bottom: 190px;
	}
  .float-left {
    float: left;
  }
	.slider .slider_btn  button {
		background: 
		#df433b;
		border-radius: 3px;
		color: white;
		display: inline-block;
		border-style:none;
		font-size: 20px;
		font-weight: 500;
		line-height: 40px;
		padding: 0px 16px 3px 16px;
		text-decoration: none;
		transition: all 300ms ease-in 0s;
		margin-bottom:110px;
		margin-top:0px;
		border-radius: 50px;
	}
	
	.slider .slider_btn  button:hover {
		background:#363f4d;
	}
	.slider  h2 strong, .slider  h3 strong {
		color: black;
		font-weight: 700;
	}
	.slider  h2 {
		color: black;
		font-size: 40px;
		font-weight: 300;
		line-height: 1;
		margin-bottom: 15px;
		padding: 0;
		text-transform: inherit;
	}
	.slider  h3 {
		color: black;
		font-size: 40px;
		font-weight: 300;
		line-height: 1;
		margin-bottom: 15px;
		padding: 0;
		text-transform: inherit;
	}




	/* down slider */
	.owl-carousel .item  {
		padding-top: 80px;
		padding-bottom: 60px;
	}
	.owl-carousel .item  img{
		filter: grayscale(1);
		opacity: 0.2;
		transition: 0.3s;
	}
	.owl-carousel .item :hover {
		opacity: 1.0;
		filter: unset !important;
	}
/* nabbar */
.back-bg {
  background-color: #005e7c;
}



.client-bg{
	background: url(../assets/images/happy-clients-bg.jpg);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: inherit;
	margin: 0px 0px 0px 0px;
	padding: 0px 0px 30px 0px;
}
.client-bg h2{
	margin: 0px 0px 0px 0px;
    padding: 0px 0px 10px 0px;
    font-size: 42px;
    font-weight: 700;
    color: #000000;
    position: relative;
}
.client-bg h2:before{
	content: '';
	bottom: 0;
	left: 0;
	position: absolute;
	border-bottom: 6px solid #005e7d;
	animation: borderAnimation1 3s ease forwards;	
}
.client-bg h2 span{
	margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
    font-weight: 400;
    color: #000000;
}
.client-bg ul{
	margin: 40px 0px 0px 0px;
	padding: 0px 0px 0px 0px;
	text-align: center;
}
.client-bg ul li{
	margin: 35px 0px 0px 0px;
	padding: 0px 0px 0px 0px;
	list-style-type: none;
}
.client-bg ul li .box{
	margin: 0px auto 0px;
	background: #ffffff;
	box-shadow: 0px 1px 10px -3px #ccc;
	padding: 30px;
    background: #fff;
    min-height: 255px;
	position: relative;
	width: 90%;
}
.client-bg ul li .box img{
	margin: 0px 0px 0px 0px;
	padding: 0px 0px 0px 0px;
	width: 70px;
	background: #ffffff;
	position: absolute;
	top: -35px;
    left: 4%;
}
.client-bg ul li .box h4{
	margin: 20px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
    font-size: 20px;
    font-weight: 400;
    color: #000000;
	text-align: left;
}
.client-bg ul li .box p{
	margin: 10px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
    font-size: 16px;
    font-weight: 300;
    color: #000000;
	text-align: left;
}
.client-bg ul li .box .box-btn{
	margin: 10px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
	float: left;
}
.client-bg ul li .box .box-btn a{
	margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
    font-size: 20px;
    font-weight: 400;
	text-decoration: none;
	color: #337ab7;
}
.client-bg ul li .box .box-btn a:hover{
	color: #23527c;
}
.articles h4{
  font-size: 18px;
  margin: 0 0 10px;
  padding: 0 30px 0 0;
  line-height: 1.5;
  color: #000;
  position: relative;
  text-align: left !important;
}

.articles p{
  font-size: 18px;
  margin: 0 0 10px;
  padding: 0 30px 0 0;
  line-height: 1.5;
  color: red;
  position: relative;
  text-align: left !important;
}

.padding-tops {
  padding-top: 130px;
}

.videoWrappers iframe {
  position: absolute;
  top: -90px;
  left: 30px;
  width: 100%;
  height: 100%;
}
ul.slides {
  display: block;
  position: relative;
  height: 600px;
  margin: 0;
  padding: 0;
  overflow: hidden;
  list-style: none;
}

.slides * {
  user-select: none;
  -ms-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
}

ul.slides input {
  display: none; 
}


.slide-container { 
  display: block; 
}

.slide-image {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  opacity: 0;
  transition: all .7s ease-in-out;
}   

.slide-image img {
  width: auto;
  min-width: 100%;
  height: 100%;
}

.carousel-controls {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  font-size: 100px;
  line-height: 600px;
  color: #fff;
}

.carousel-controls label {
  display: none;
  position: absolute;
  padding: 0 20px;
  opacity: 0;
  transition: opacity .2s;
  cursor: pointer;
}

.slide-image:hover + .carousel-controls label{
  opacity: 0.5;
}

.carousel-controls label:hover {
  opacity: 1;
}

.carousel-controls .prev-slide {
  width: 49%;
  text-align: left;
  left: 0;
}

.carousel-controls .next-slide {
  width: 49%;
  text-align: right;
  right: 0;
}

.carousel-dots {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 20px;
  z-index: 999;
  text-align: center;
}

.carousel-dots .carousel-dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #fff;
  opacity: 0.5;
  margin: 10px;
}

input:checked + .slide-container .slide-image {
  opacity: 1;
  transform: scale(1);
  transition: opacity 1s ease-in-out;
}

input:checked + .slide-container .carousel-controls label {
   display: block; 
}

input#img-1:checked ~ .carousel-dots label#img-dot-1,
input#img-2:checked ~ .carousel-dots label#img-dot-2,
input#img-3:checked ~ .carousel-dots label#img-dot-3,
input#img-4:checked ~ .carousel-dots label#img-dot-4,
input#img-5:checked ~ .carousel-dots label#img-dot-5,
input#img-6:checked ~ .carousel-dots label#img-dot-6 {
opacity: 1;
}


input:checked + .slide-container .nav label { display: block; }
  .carousel-bg1 .pure {
    color: white;
  }


.animation-top-sentence {
  animation: fadeInRight 2s ease-in-out;
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translateX(300px);
  }
  to {
    opacity: 1;
  }
}
.animation-secound-sentence {
  animation: fadeInLeft 2s ease-in-out;
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translateX(-300px); /* Start from off-screen right */
  }
  to {
    opacity: 1;
    transform: translateX(0); /* Move to original position */
  }
}
.animation-btn-sentence {
  animation: fadeInUp 2s ease-in-out;
}
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(300px);
  }
  to {
    opacity: 1;
  }
}

#my-pics{
background: #ffffff !important;
}
/* .carousel{
  bottom:60px;
} */
.carousel-inner{
  bottom:60px;
}
.logo img{
  position: relative;
  z-index: 3;
}
.flex{
  position: relative;
  z-index: 3;;
}