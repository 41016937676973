.backgroung-image-LeaderShip {
    background-image: url(../assets/images/behavioural_skill.jpg);
    background-size: 100% 100%;
    width: 100%;
    background-repeat: no-repeat;
    height: 800px;
}
.para1 {
    text-align: left;
    line-height: 2;
    font-size: 14px;
}
.thisPractices img {
    width: 45%;
}

 .thisPractices .para-Main  {
    color: #005e7d;
    font-weight: 500;
    text-align: left;
}
.pt-5 {
    text-align: left;
}
.pt-0 {
    text-align: left;
}
