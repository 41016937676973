
.backgroung-image {
    background-image: url(../assets/images/ABOUT_US\ \(1\).jpg);
    background-size: 100% 100%;
    width: 100%;
    background-repeat: no-repeat;
	height: 700px;
}

.header-about-content h1{
    text-align: center;
    padding-top: 230px;
    padding-bottom: 230px;
    color: #005e7c !important;
    font-weight: 700;
    margin: 0;
    letter-spacing: 1px;
    margin-left: 32px;
    justify-content: center;
    align-items: center;
}
.about-success-work h2 {
    text-align: left;
}
@media (min-width: 768px) {
    .col-md-6 {
      flex: 0 0 auto;
      width: 100%;
    }
  }

.what-we-dosss {
	font-size: 45px;
    font-weight: 500;
    color: black;
    line-height: 60px;
    letter-spacing: 1px;
}
.boxss {
    padding: 40px; /* Adjust padding as needed */
    border: 1px solid #ccc; /* Add a 1px solid border with a light gray color */
    border-radius: 5px; /* Add some border radius for rounded corners */
    margin: 0 10px; /* Add some margin between boxes */
    height: 350px;
}
.what-we-dossss {
	font-size: 40px;
    font-weight: 700;
    color: #005e7d;
    line-height: 30px;
    letter-spacing: 1px;
    font-size: 45px;
	text-align: left !important;
}

.about-success-work {
    padding-top: 70px;
}
.about-success-work .hear-underline {
    justify-content: center;
    text-align: center;
    align-items: center;
    height: 6x;
    color: #005e7c;
}
.about-success-work p {
     line-height: 28px;
     padding: 0;
     margin: 0 0 20px;
     color: #333333;
     text-align: justify;
}
.about-nav {
    padding-top: 13px;
}