.backgroung-image-coaching{
    background-image: url(../assets/images/Coaching.jpg);
    background-size: 100% 100%;
    width: 100%;
    background-repeat: no-repeat;
}
.testimonials-bg h6 {
    float: left;
}
.what-we-dossw {
  font-size: 30px;
  font-weight: 600;
}
.testimonials-bg span {
    color: #005e7c;
}
.coaching-img {
    width: 55%;
    align-items: center;
}
.how-do-coach-img{
    background-image: url(../assets/images/Cross-culture-sec.jpg);
    background-size: cover;
    width: 100%;
    background-repeat: no-repeat;
}
.how-do-coach-img  p{
    text-align: left;
}
.why-choaching {
    font-size: 30px;
   
    font-weight: 500;

}
.why {
    font-size: 30px;
    color: black;
    font-weight: 500;

}
.how-do-coach-img p {
    text-align: justify;
    line-height: 28px;
padding: 0;
margin: 0 0 20px;
color: #333333;
}