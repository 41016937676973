.backgroung-image-advancedtechnology {
    background-image: url(../assets/images/Advance_Technology_Domain\ \(1\).jpg);
    background-size: 100% 100%;
    width: 100%;
    background-repeat: no-repeat;
	height: 800px;
}

.testimonials-bg p{
    line-height: 28px;
    padding: 0;
    margin: 0 0 20px;
    color: #333333;
    text-align: justify;
    padding-top: 10px;
}

.thisPractices img{
	width:60%;
}
.thisPractices{background:url(../assets/images/this-practice-bg.jpg) repeat; text-align:center; padding:80px 0;}


.programForm{background:url(../assets/images/noetc-science-form-bg.jpg) no-repeat; padding:55px 0;background-size: cover;}
    .programForm h2{text-align:center; color:#fff; margin:0 0 12px; padding:0; letter-spacing:2px; font-size:36px;}
	.programForm h2 span{ font-weight:300; }
	.programForm input {margin: 0;background: rgba(85, 145, 164, 0.5);width: 100%;padding: 4px;border: solid 1px #ccc;font-size: 14px;color: #fff; font-weight: 500; border:none;     border-radius: 0;height: auto;padding:10px 12px;}
	.programForm textarea {margin: 0;background: rgba(85, 145, 164, 0.5);width: 100%;padding: 4px;border: solid 1px #ccc;font-size: 14px;color: #fff;    font-weight: 500; border:none;     border-radius: 0;height:90px;padding:12px;}
	.programForm  .container p{text-align:center; color:#fff; font-size:20px; font-weight:400; margin:0 0 35px;} 
	.programForm .submit{background:none; color:#fff; font-weight:600; border:solid 2px #fff;  border-radius: 18px; font-size:13px; padding:8px 22px; }
	.programForm .submit:hover{background:#fff; color:#000;}
  	.programForm .form-group{margin:0 0 10px;}
	.programForm input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
		color: #ab9d9e;
		opacity: 1; /* Firefox */
	}

	.programForm input:-ms-input-placeholder { /* Internet Explorer 10-11 */
	  color: #ab9d9e;
	}

	.programForm input::-ms-input-placeholder { /* Microsoft Edge */
		color: #ab9d9e;
	}
	.programForm textarea::-webkit-input-placeholder {
	color: #ab9d9e;
	}

	.programForm textarea:-moz-placeholder { /* Firefox 18- */
	color: #ab9d9e;  
	}

	.programForm textarea::-moz-placeholder {  /* Firefox 19+ */
	color: #ab9d9e;  
	}

	.programForm textarea:-ms-input-placeholder {
	color: #ab9d9e;  
	}
.programForm .container{padding: 0 70px;}
.programFormInner{display:block; overflow:hidden; margin:0 0 20px;}
.programFormInner .col-md-4{padding:0; margin:0;}
.programFormInner .col-md-8{padding-right:0;}
.programFormInner .col-md-6{padding:0 8px 0 0; margin:0 0 10px;}
.programFormInner .phone-in{padding:0;}
.programFormInner .col-md-6:last-child{padding:0;}
.programFormInner .col-md-12{padding:0; margin:0;}
.programForm fieldset {
    padding: 20px 20px;
    margin: 0 2px;
    border: 1px solid #c0c0c0;
}
.programForm fieldset.heightview {
    min-height: 290px;
}
.programForm legend {
  display: block;
    width: auto;
    margin-bottom:0;
    font-size: 18px;
    line-height: inherit;
    color: #fff;
    border: none;
    padding:5px 18px 20px 10px;
}
.programFormInner .row{margin:0 0 10px 0;}
.programFormInner .col-md-3{padding:0;}
.programFormInner  ul{margin:0;}
.programFormInner  ul li{list-style:none;}
.programFormInner  ul li label{width:100%;}	
.programFormInner  ul li label input{width:20px; float:left; margin: 3px 0 0;}	
.programFormInner ul li label span{float:left; background:none; margin:0; color:#fff; font-weight:400;font-size:15px;width:92%;}
.programForm .captchatext input{padding: 7px 12px;}

.programForm h2 {font-size:20px;}
.programForm .container {
    padding: 0 20px;
}
.programFormInner .col-md-8{padding:0px 0px 0px 0px;}
.programFormInner .col-md-6 {
    padding: 1px;}
	.programForm legend {   width: 100%;}
	.innerContent h1{ font-size: 20px; line-height:30px; padding: 0 0 20px; margin: 0 0 20px;}
	.innerContent h1:before{left: 50%;}
	.programForm{  background-size: cover;}
	.footerTop{display:none;}
	.footerOut{padding:20px 0 20px;}
	.aboutIntro {  padding: 0 0;}
	.captchatext {
    width: 152px;}
	.programFormInner ul li label input{width:15px;}
	.programFormInner ul li label span{font-size: 16px; width:auto; margin-left: 10px;}
	.programForm .container p{font-size:15px;}
	.programForm .container p br{display:none;}
	.programFormInner .col-md-4{margin: inherit;padding-right: 5px;}
	.programForm fieldset {  padding: 20px;}
    .popvideoOut .modal-dialog {  width: 800px;}
/* .enquireNowPop .modal-content{padding:30px; background:url(../images/enquiry-bg.jpg) no-repeat; border:solid 1px #ccc;} */
.enquireNowPop .modal-body{padding:0;} 
.enquireNowOut .col-md-12{padding:0;}
.enquireNowOut h2{padding:0; margin:0; color:#fff;}
.enquireNowOut p{padding:0; margin:0 0 20px; color:#fff;}
.enquireNowOut .form-control{background:none; color:#fff; }
.enquireNowOut .form-control input{border-radius: 0;}
.enquireNowOut .captchatext input{background:none;padding:6px; border:solid 1px #ccc;}

@media screen and (max-width: 991px) {
    .programForm textarea {
	
		width: 100%;

	  }
	  .programForm input {
		width: 148%;

	  }
	  .programFormInner .col-md-8 {
		padding: 0px 5px 0px 0px;
	  }
  }